import React from 'react';
import Box from './Box';
import CustomText from './CustomText';
import Button from './Button';
import FlowerCheckIcon from '@assets/icons/FlowerCheckIcon';
import { CANDLELIGHT, GRANNY_SMITH, MOUNTAIN_MEADOW, MYSTIC, OUTER_SPACE, WHITE } from '@constants';
import Card from './Card';

type Props = {
  title?: string;
  price?: string;
  pricePeriod?: string;
  benefits: { benefit?: string; isChecked?: boolean; isMinus?: boolean }[];
  buttonLabel: string;
  buttonId?: string;
  isPlanActive?: boolean;
  isPlanAvailable?: boolean;
  buttonAction?: () => void;
  isButtonLoading?: boolean;
  hasButton: boolean;
  textColor: string;
  titleColor: string;
  borderColor: string;
  backgroundColor: string;
  separatorColor: string;
  mostPopular: boolean;
  firstPlan: boolean;
};

const PricingPlanCardMobile: React.FC<Props> = ({
  title,
  price,
  pricePeriod,
  benefits,
  isPlanActive,
  isPlanAvailable = true,
  buttonLabel,
  buttonId,
  buttonAction,
  isButtonLoading,
  hasButton,
  textColor,
  titleColor,
  borderColor,
  backgroundColor,
  separatorColor,
  mostPopular,
  firstPlan,
}) => {
  const header = (
    <Box display={'flex'} flexDirection={'column'} padding={'24px 24px 40px 24px'}>
      <Box
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        height={'42px'}
      >
        <CustomText fontSize={22} lineHeight={'22px'} color={titleColor}>
          {title}
        </CustomText>
      </Box>
      <Box display={'flex'} alignItems='flex-end'>
        <CustomText fontSize={32} lineHeight={'32px'} fontFamily={'Poppins'} fontWeight={700} color={textColor}>
          {price}
        </CustomText>
        {pricePeriod ? (
          <CustomText
            fontSize={16}
            lineHeight={'16px'}
            letterSpacing={'-0.3px'}
            fontFamily={'Poppins'}
            marginBottom={'4px'}
            color={GRANNY_SMITH}
          >
            {pricePeriod}
          </CustomText>
        ) : (
          <></>
        )}
      </Box>
      {hasButton ? (
        <Button
          id={buttonId}
          height={50}
          fontSize={14}
          background={
            isPlanActive || firstPlan ? MYSTIC : !isPlanAvailable ? WHITE : mostPopular ? CANDLELIGHT : OUTER_SPACE
          }
          color={isPlanActive || !isPlanAvailable ? GRANNY_SMITH : mostPopular || firstPlan ? OUTER_SPACE : WHITE}
          label={buttonLabel}
          disabled={isPlanActive || !isPlanAvailable}
          onClick={buttonAction}
          borderColor={!isPlanAvailable ? MYSTIC : 'transparent'}
          marginTop={'44px'}
          isLoading={isButtonLoading}
        ></Button>
      ) : (
        <></>
      )}
    </Box>
  );
  const body = (
    <Box borderTop={`1px solid ${separatorColor}`} padding={'24px'}>
      {benefits.map((benefitObject, index) => {
        return (
          !benefitObject.isChecked && (
            <Box
              key={index}
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'flex-start'}
              alignItems={'center'}
              height={'24px'}
              marginBottom={'8px'}
            >
              <Box>
                <CustomText fontWeight={700} fontSize={16} lineHeight={'24px'} color={textColor}>
                  {benefitObject.benefit}
                </CustomText>
              </Box>
            </Box>
          )
        );
      })}
      {benefits.map((benefitObject, index) => {
        return (
          benefitObject.isChecked && (
            <Box
              key={index}
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'flex-start'}
              alignItems={'center'}
              height={'24px'}
              marginBottom={'8px'}
            >
              <Box>
                <Box display='flex' alignItems={'center'}>
                  <Box marginRight={'12px'}>
                    <FlowerCheckIcon height={20} width={20} fill={MOUNTAIN_MEADOW} />
                  </Box>
                  <CustomText color={textColor}>{benefitObject.benefit}</CustomText>
                </Box>
              </Box>
            </Box>
          )
        );
      })}
    </Box>
  );
  return <Card border={`1px solid ${borderColor}`} backgroundColor={backgroundColor} header={header} body={body} />;
};

export default PricingPlanCardMobile;
