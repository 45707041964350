import { MYSTIC, OUTER_SPACE, WHITE } from '@constants';
import { PricingPlan } from '@pageSections/pricingPage/price/types';
import React from 'react';
import PricingPlanCardMobile from './PricingPlanCardMobile';

type Props = {
  slice: PricingPlan;
  title?: string;
  subtitle?: string;
  price?: string;
  pricePeriod?: string;
  isPlanActive?: boolean;
  isPlanAvailable?: boolean;
  buttonLabel?: string;
  buttonAction?: () => void;
  buttonId?: string;
  isButtonLoading?: boolean;
  isBlankTheme?: boolean;
  isLightTheme?: boolean;
  isDarkTheme?: boolean;
  mostPopular: boolean;
  firstPlan: boolean;
};

const PricingPlanCardMobileSlice: React.FC<Props> = (props) => {
  const {
    slice,
    title,
    subtitle,
    price,
    pricePeriod,
    isPlanActive,
    isPlanAvailable,
    buttonLabel,
    buttonAction,
    buttonId,
    isButtonLoading,
    isBlankTheme,
    isLightTheme,
    isDarkTheme,
    mostPopular,
    firstPlan,
  } = props;
  return (
    <PricingPlanCardMobile
      firstPlan={firstPlan}
      mostPopular={mostPopular}
      title={title}
      subtitle={subtitle}
      price={price}
      pricePeriod={pricePeriod}
      benefits={slice.items ? slice.items : []}
      isPlanActive={isPlanActive}
      isPlanAvailable={isPlanAvailable}
      buttonLabel={buttonLabel ? buttonLabel : 'Action'}
      buttonAction={buttonAction}
      buttonId={buttonId}
      isButtonLoading={isButtonLoading}
      hasButton={!isBlankTheme}
      textColor={isDarkTheme ? WHITE : OUTER_SPACE}
      textFontWeight={isBlankTheme ? 700 : 400}
      titleColor={isDarkTheme ? WHITE : OUTER_SPACE}
      borderColor={isDarkTheme ? OUTER_SPACE : isBlankTheme ? WHITE : MYSTIC}
      backgroundColor={isDarkTheme ? OUTER_SPACE : isBlankTheme || isLightTheme ? WHITE : MYSTIC}
      separatorColor={isBlankTheme ? WHITE : ' #D8E4E4'}
      justifyContentText={isBlankTheme ? 'flex-start' : 'center'}
    />
  );
};

export default PricingPlanCardMobileSlice;
