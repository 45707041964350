import React from 'react';
import styled from '@emotion/styled';
import { graphql, PageProps } from 'gatsby';
import SEO from '@components/SEO';
import { HeaderSubtitle, HeaderTitle, theme } from '../site/styles';
import FooterSection from '@pageSections/common/footer';
import { IPrismicPricing, PageContext } from '@appTypes';

import CallToActionSection from '@pageSections/common/callToAction';
import Price from '@pageSections/pricingPage/price';
import FAQSection from '@pageSections/pricingPage/faq';
import { SectionWrapper as SectionWrapperStyles } from '@styles';

const { breakpoints } = theme;

const PricingPage: React.FC<PageProps<IPrismicPricing, PageContext>> = ({
  data: { prismicData, prismicFooterData, prismicCtaData },
  pageContext,
  location,
}) => {
  const { locale } = pageContext;
  const data = prismicData!.edges[0].node.data;

  const { meta_title, meta_description, meta_image, meta_url, header_title, header_subtitle } = data;

  return (
    <>
      <Container style={{ marginTop: 34 }}>
        <SEO
          title={meta_title}
          description={meta_description}
          image={meta_image.url}
          url={meta_url}
          pathname={location.pathname}
          name='Hyperhuman'
          locale={locale}
        />

        <SectionWrapper>
          <HeaderTitle>{header_title}</HeaderTitle>
          <HeaderSubtitle>{header_subtitle}</HeaderSubtitle>
        </SectionWrapper>

        <Price />

        <FAQSection {...data} />
      </Container>
      <CallToActionSection prismicCtaData={prismicCtaData} />
      <FooterSection prismicFooterData={prismicFooterData} subscribeForm />
    </>
  );
};

export default PricingPage;

const SectionWrapper = styled(SectionWrapperStyles)`
  padding: 0 24px;
  margin-bottom: 80px;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    margin-bottom: 100px;
  }
`;

const Container = styled.div`
  margin-top: 34px;
  margin-bottom: 70px;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    margin-bottom: 110px;
  }
`;

export const pageQuery = graphql`
  query PricingPageQuery($locale: String!) {
    prismicData: allPrismicPricing(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          lang
          data {
            meta_title
            meta_keywords
            meta_description
            meta_image {
              url
            }
            meta_url
            header_title
            header_subtitle
            body1 {
              ... on PrismicPricingDataBody1PricingPlan {
                id
                items {
                  plan_feature
                  plan_check_icon
                }
                primary {
                  plan_bg_color
                  plan_button_id
                  plan_button_label
                  plan_button_link
                  plan_button_link_external
                  plan_name
                  plan_price {
                    text
                    html
                  }
                  plan_theme
                }
              }
            }
            faq_title
            faq_subtitle
            faq_items {
              faq_question
              faq_answer {
                html
              }
            }
          }
        }
      }
    }
    prismicFooterData: allPrismicIndex(filter: { lang: { eq: $locale } }) {
      ...FooterFieldsFragment
    }
    prismicCtaData: allPrismicIndex(filter: { lang: { eq: $locale } }) {
      ...CtaFieldsFragment
    }
  }
`;
