import FlowerCheckIcon from '@assets/icons/FlowerCheckIcon';
import { CANDLELIGHT, GRANNY_SMITH, MOUNTAIN_MEADOW, MYSTIC, OUTER_SPACE, WHITE } from '@constants';
import React from 'react';
import Box from './Box';
import Button from './Button';
import Card from './Card';
import CustomText from './CustomText';

const PricingPlanCard: React.FC<{
  title?: string;
  price?: string;
  pricePeriod?: string;
  benefits: {
    benefit?: string;
    isChecked?: boolean;
    isMinus?: boolean;
    height: string;
    subtitle?: string;
    multiLine?: boolean;
  }[];
  buttonLabel: string;
  buttonId?: string;
  isPlanActive?: boolean;
  isPlanAvailable?: boolean;
  buttonAction?: () => void;
  isButtonLoading?: boolean;
  hasButton: boolean;
  textColor: string;
  textFontWeight: number;
  titleColor: string;
  borderColor: string;
  backgroundColor: string;
  separatorColor: string;
  justifyContentText: string;
  mostPopular: boolean;
  firstPlan: boolean;
}> = ({
  title,
  price,
  pricePeriod,
  benefits,
  isPlanActive,
  isPlanAvailable = true,
  buttonLabel,
  buttonId,
  buttonAction,
  isButtonLoading,
  hasButton,
  textColor,
  textFontWeight,
  titleColor,
  borderColor,
  backgroundColor,
  separatorColor,
  justifyContentText,
  mostPopular,
  firstPlan,
}) => {
  const header = (
    <Box display={'flex'} flexDirection={'column'} padding={'24px 24px 40px 24px'} height={'260px'}>
      <Box
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        height={'42px'}
        mb={'16px'}
      >
        <CustomText fontSize={22} lineHeight={'22px'} color={titleColor}>
          {title}
        </CustomText>
      </Box>
      <Box display={'flex'} alignItems='flex-end'>
        <CustomText fontSize={32} lineHeight={'32px'} fontFamily={'Poppins'} fontWeight={700} color={textColor}>
          {price}
        </CustomText>
        {pricePeriod ? (
          <CustomText
            fontSize={16}
            lineHeight={'16px'}
            letterSpacing={'-0.3px'}
            fontFamily={'Poppins'}
            marginBottom={'4px'}
            color={GRANNY_SMITH}
          >
            {pricePeriod}
          </CustomText>
        ) : (
          <></>
        )}
      </Box>
      {hasButton ? (
        <Button
          id={buttonId}
          height={50}
          fontSize={14}
          background={
            isPlanActive || firstPlan ? MYSTIC : !isPlanAvailable ? WHITE : mostPopular ? CANDLELIGHT : OUTER_SPACE
          }
          color={isPlanActive || !isPlanAvailable ? GRANNY_SMITH : mostPopular || firstPlan ? OUTER_SPACE : WHITE}
          label={buttonLabel}
          disabled={isPlanActive || !isPlanAvailable}
          onClick={buttonAction}
          borderColor={!isPlanAvailable ? MYSTIC : 'transparent'}
          marginTop={'44px'}
          isLoading={isButtonLoading}
        ></Button>
      ) : (
        <></>
      )}
    </Box>
  );
  const body = (
    <Box borderTop={`1px solid ${separatorColor}`} padding={'24px 0 0 0'}>
      {benefits.map(
        (benefitObject: {
          benefit?: string;
          isChecked?: boolean;
          isMinus?: boolean;
          height: string;
          subtitle?: string;
          multiLine?: boolean;
        }) => {
          return (
            <Box
              key={benefitObject.benefit}
              display={'flex'}
              flexDirection={'row'}
              justifyContent={justifyContentText}
              alignItems={'center'}
              height={benefitObject.height}
            >
              <Box
                height={benefitObject.height}
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'flex-start'}
                alignItems={'center'}
              >
                {benefitObject.isMinus ? <CustomText color={textColor}>-</CustomText> : <></>}
                {benefitObject.isChecked ? <FlowerCheckIcon height={20} width={20} fill={MOUNTAIN_MEADOW} /> : <></>}
                <Box display={'flex'} flexDirection={'column'} justifyContent={'flex-start'} alignItems={'center'}>
                  {benefitObject.benefit ? (
                    <>
                      {benefitObject.multiLine ? (
                        <>
                          <CustomText fontSize={16} lineHeight={'24px'} color={textColor} fontWeight={textFontWeight}>
                            {benefitObject.benefit.split('\\n')[0]}
                          </CustomText>
                          <CustomText fontSize={16} lineHeight={'24px'} color={textColor} fontWeight={textFontWeight}>
                            {benefitObject.benefit.split('\\n')[1]}
                          </CustomText>
                        </>
                      ) : (
                        <CustomText fontSize={16} lineHeight={'24px'} color={textColor} fontWeight={textFontWeight}>
                          {benefitObject.benefit}
                        </CustomText>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                  {benefitObject.subtitle ? (
                    <CustomText
                      fontSize={16}
                      lineHeight={'16px'}
                      fontWeight={600}
                      fontStyle={'italic'}
                      color={'#707173'}
                      letterSpacing={'-0.3px'}
                    >
                      {benefitObject.subtitle}
                    </CustomText>
                  ) : (
                    <></>
                  )}
                </Box>
              </Box>
            </Box>
          );
        },
      )}
    </Box>
  );
  return <Card border={`1px solid ${borderColor}`} backgroundColor={backgroundColor} header={header} body={body} />;
};

export default PricingPlanCard;
