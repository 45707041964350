import { OUTER_SPACE, WHITE } from '@constants';
import React, { ReactNode } from 'react';
import Box from './Box';

type Props = {
  header?: ReactNode;
  body?: ReactNode;
  footer?: ReactNode;
  borderRadius?: number;
  backgroundColor?: string;
  border?: string;
  padding?: string;
};

const Card: React.FC<Props> = ({
  header,
  body,
  footer,
  backgroundColor = WHITE,
  borderRadius = 16,
  border = `1px solid ${OUTER_SPACE}`,
  padding = '0px',
}) => {
  return (
    <Box
      padding={padding}
      width={'100%'}
      height={'100%'}
      backgroundColor={backgroundColor}
      borderRadius={borderRadius}
      border={border}
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'space-between'}
      overflow={'hidden'}
    >
      <Box>
        {header}
        {body}
      </Box>
      {footer}
    </Box>
  );
};

export default Card;
