import Box from '@components/Box';
import React, { useState } from 'react';
import { PricingPlan } from './types';
import styled from '@emotion/styled';
import { GRANNY_SMITH, MYSTIC, OUTER_SPACE, WHITE } from '@constants';
import PricingPlanCardSlice from '@components/PricingPlanCardSlice';
import { theme } from '@styles';
import PricingPlanCardMobileSlice from '@components/PricingPlanCardMobileSlice';
import Button from '@components/Button';
import CustomText from '@components/CustomText';
import { Link, navigate } from 'gatsby';

const { breakpoints } = theme;

type Props = {};

const PLAN_BENEFITS: PricingPlan[] = [
  {
    id: 'headlines',
    items: [
      { benefit: 'Video Storage (Minutes)', height: '66px' },
      { benefit: 'Workout Video Downloads', height: '66px' },
      { benefit: 'Team Members', height: '66px' },
      { benefit: 'Stock Video Library', height: '80px' },
      { benefit: 'Mobile App for Clients', height: '80px' },
      { benefit: 'Auto-Generated Social Clips', height: '66px' },
      { benefit: 'Branding & Watermarks', height: '66px' },
      { benefit: 'AI Video Processing', height: '80px' },
      { benefit: 'Customer Support', height: '66px' },
      { benefit: 'Content Delivery API', height: '80px' },
    ],
    primary: { planId: 'headlines' },
  },
  {
    id: 'mastery',
    items: [
      { benefit: '1500', height: '66px' },
      { benefit: '25 / Month', height: '66px' },
      { benefit: '2', height: '66px' },
      { benefit: '2000+ Free & Premium Videos', multiLine: true, height: '80px' },
      { benefit: 'White-label Options', isChecked: true, height: '80px' },
      { isChecked: true, height: '66px' },
      { isChecked: true, height: '66px' },
      { benefit: 'Standard', isChecked: true, height: '80px' },
      { benefit: 'Standard', isChecked: true, height: '80px' },
      { benefit: '1,000 Requests / Hour', height: '80px' },
    ],
    primary: {
      planId: 'mastery',
      title: 'Mastery',
      planPriceMonthly: '$179',
      planPriceAnnualy: '$143',
    },
  },
  {
    id: 'elite',
    items: [
      { benefit: '2500', height: '66px' },
      { benefit: '50 / Month', height: '66px' },
      { benefit: '3', height: '66px' },
      { benefit: '2000+ Free & Premium Videos', multiLine: true, height: '80px' },
      { benefit: 'White-label Options', isChecked: true, height: '80px' },
      { isChecked: true, height: '66px' },
      { isChecked: true, height: '66px' },
      { benefit: 'Priority', isChecked: true, height: '80px' },
      { benefit: 'Priority', isChecked: true, height: '80px' },
      { benefit: '3,000 Requests / Hour', height: '80px' },
    ],
    primary: {
      planId: 'elite',
      title: 'Elite',
      planPriceMonthly: '$286',
      planPriceAnnualy: '$229',
    },
  },
  {
    id: 'legend',
    items: [
      { benefit: '3500', height: '66px' },
      { benefit: '75 / Month', height: '66px' },
      { benefit: '5', height: '66px' },
      { benefit: '2000+ Free & Premium Videos', multiLine: true, height: '80px' },
      { benefit: 'White-label Options', isChecked: true, height: '80px' },
      { isChecked: true, height: '66px' },
      { isChecked: true, height: '66px' },
      { benefit: 'Priority', isChecked: true, height: '80px' },
      { benefit: 'Priority', isChecked: true, height: '80px' },
      { benefit: '10,000 Requests / Hour', height: '80px' },
    ],
    primary: {
      planId: 'legend',
      title: 'Legend',
      planPriceMonthly: '$399',
      planPriceAnnualy: '$319',
    },
  },
];

const MOBILE_PLAN_BENEFITS: PricingPlan[] = [
  {
    id: 'mastery',
    items: [
      { benefit: '1500 Minutes Video Storage' },
      { benefit: '25 Workout Video Downloads / Month' },
      { benefit: '2 Team Members' },
      { benefit: '2000+ Free & Premium Videos', isChecked: true },
      { benefit: 'Mobile App for Clients', isChecked: true },
      { benefit: 'Auto-Generated Social Clips', isChecked: true },
      { benefit: 'Branding & Watermarks', isChecked: true },
      { benefit: 'Standard AI Video Processing', isChecked: true },
      { benefit: 'Standard Customer Support', isChecked: true },
      { benefit: '1,000 API Requests / Hour', isChecked: true },
    ],
    primary: {
      planId: 'mastery',
      title: 'Mastery',
      planPriceMonthly: '$179',
      planPriceAnnualy: '$143',
    },
  },
  {
    id: 'elite',
    items: [
      { benefit: '2500 Minutes Video Storage' },
      { benefit: '50 Workout Video Downloads / Month' },
      { benefit: '3 Team Members' },
      { benefit: '2000+ Free & Premium Videos', isChecked: true },
      { benefit: 'Mobile App for Clients', isChecked: true },
      { benefit: 'Auto-Generated Social Clips', isChecked: true },
      { benefit: 'Branding & Watermarks', isChecked: true },
      { benefit: 'Priority AI Video Processing', isChecked: true },
      { benefit: 'Priority Customer Support', isChecked: true },
      { benefit: '3,000 API Requests / Hour', isChecked: true },
    ],
    primary: {
      planId: 'elite',
      title: 'Elite',
      planPriceMonthly: '$286',
      planPriceAnnualy: '$229',
    },
  },
  {
    id: 'legend',
    items: [
      { benefit: '3500 Minutes Video Storage' },
      { benefit: '75 Workout Video Downloads / Month' },
      { benefit: '5 Team Members ' },
      { benefit: '2000+ Free & Premium Videos', isChecked: true },
      { benefit: 'Mobile App for Clients', isChecked: true },
      { benefit: 'Auto-Generated Social Clips', isChecked: true },
      { benefit: 'Branding & Watermarks', isChecked: true },
      { benefit: 'Priority AI Video Processing', isChecked: true },
      { benefit: 'Priority Customer Support', isChecked: true },
      { benefit: '10,000 API Requests / Hour', isChecked: true },
    ],
    primary: {
      planId: 'legend',
      title: 'Legend',
      planPriceMonthly: '$399',
      planPriceAnnualy: '$319',
    },
  },
];

const Price: React.FC<Props> = () => {
  const [selectedLabelIndex, setSelectedLabelIndex] = useState<0 | 1>(1);

  const getBenefitPrice = (slice: PricingPlan) => {
    if (slice.primary.planId === 'free') {
      return '$0';
    } else if (slice.primary.planId === 'unavailable') {
      return undefined;
    } else {
      return selectedLabelIndex === 0 ? slice.primary.planPriceMonthly : slice.primary.planPriceAnnualy;
    }
  };

  const getPricePeriod = (slice: PricingPlan) => {
    if (slice.primary.planId === 'headlines' || slice.primary.planId === 'custom') {
      return undefined;
    }
    return '/month';
  };

  const handlePlanSelect = (planId: string) => {
    switch (planId) {
      case 'custom':
        navigate('/book-a-demo');
        break;
      default:
        window.open(`https://team.hyperhuman.cc/create-account?utm_source=${planId}`, '_blank');
        break;
    }
  };

  return (
    <Wrapper>
      <Box backgroundColor={'#fff'} display={'flex'} flexDirection={'column'} justifyContent={'center'}>
        <PlanToggler>
          <Box display={'flex'} backgroundColor={MYSTIC} borderRadius={'12px'}>
            <SelectCell
              isSelected={selectedLabelIndex === 0}
              isFirst
              onClick={() => {
                setSelectedLabelIndex(0);
              }}
            >
              Monthly
            </SelectCell>
            <SelectCell
              isSelected={selectedLabelIndex === 1}
              isLast
              onClick={() => {
                setSelectedLabelIndex(1);
              }}
            >
              Yearly (Save 20%)
            </SelectCell>
          </Box>
        </PlanToggler>

        <BenefitsDesktop>
          {PLAN_BENEFITS.map((slice, index) => {
            return (
              <Box key={index} width={index === 0 ? 220 : 260} marginRight={'24px'} marginBottom={'80px'}>
                <PricingPlanCardSlice
                  firstPlan={index === 1}
                  title={slice.primary.title}
                  isPlanActive={false}
                  isPlanAvailable={true}
                  mostPopular={index === 2}
                  price={getBenefitPrice(slice)}
                  pricePeriod={getPricePeriod(slice)}
                  isButtonLoading={false}
                  buttonAction={() => handlePlanSelect(slice.primary.planId)}
                  buttonLabel={'GET STARTED'}
                  buttonId={`plan-button-${index}`}
                  isBlankTheme={index === 0}
                  isLightTheme={index === 1}
                  isDarkTheme={index === 2}
                  slice={slice}
                />
              </Box>
            );
          })}
        </BenefitsDesktop>

        <BenefitsMobile>
          {MOBILE_PLAN_BENEFITS.map((slice, index) => {
            return (
              <Box key={index}>
                <PricingPlanCardMobileSlice
                  key={index}
                  firstPlan={index === 0}
                  title={slice.primary.title}
                  isPlanActive={false}
                  isPlanAvailable={true}
                  mostPopular={index === 2}
                  price={getBenefitPrice(slice)}
                  pricePeriod={getPricePeriod(slice)}
                  isButtonLoading={false}
                  buttonAction={() => handlePlanSelect(slice.primary.planId)}
                  buttonLabel={index !== 0 ? 'GET STARTED' : 'TRY IT OUT'}
                  buttonId={`plan-button-${index}`}
                  isLightTheme={index === 0}
                  isDarkTheme={index === 1}
                  slice={slice}
                />
              </Box>
            );
          })}
        </BenefitsMobile>

        <EnterpriseCard>
          <CustomText marginBottom={'16px'} textAlign={'center'} fontSize={22} lineHeight={'22px'}>
            Custom Plan
          </CustomText>
          <CustomText
            textAlign={'center'}
            fontFamily={'Poppins'}
            fontWeight={700}
            fontSize={24}
            lineHeight={'28.8px'}
            letterSpacing={'-0.3px'}
          >
            Tailored for your business
          </CustomText>
          <EnterpriseButton>
            <Link to='/book-a-demo'>
              <Button fontSize={14} height={48} width={242} label={'REQUEST QUOTE'} />
            </Link>
          </EnterpriseButton>
        </EnterpriseCard>
      </Box>
    </Wrapper>
  );
};

export default Price;

const Wrapper = styled.div`
  margin: 0 auto;
`;

const EnterpriseButton = styled.div`
  margin: 24px 18px 0px 18px;
  display: flex;
  justify-content: center;
  a {
    text-decoration: none;
  }
`;

const EnterpriseCard = styled.div`
  background: #f0f5f5;
  border-radius: 16px;
  border: 1px solid #e2ebeb;
  padding: 40px 24px;
  margin: 24px;
  @media screen and (min-width: ${breakpoints.largeDesktop}) {
    width: 80%;
    margin-left: 10%;
  }
`;

const PlanToggler = styled.div`
  display: flex;
  margin: -30px auto 30px auto;
  @media screen and (min-width: ${breakpoints.largeDesktop}) {
    margin-bottom: 68px;
  }
`;

const BenefitsMobile = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 24px;
  row-gap: 47px;
  @media screen and (min-width: ${breakpoints.largeDesktop}) {
    display: none;
  }
`;

const BenefitsDesktop = styled.div`
  display: none;
  justify-content: center;
  @media screen and (min-width: ${breakpoints.largeDesktop}) {
    display: flex;
  }
`;

const SelectCell = styled.button<{ isSelected: boolean; isFirst?: boolean; isLast?: boolean }>`
  padding: 12px;
  background-color: ${({ isSelected }) => (isSelected ? WHITE : MYSTIC)};
  color: ${({ isSelected }) => (isSelected ? OUTER_SPACE : GRANNY_SMITH)};
  font-weight: bold;
  border-radius: 8px;
  border: 0;
  cursor: pointer;
  margin: ${({ isFirst, isLast }) => (isFirst ? '6px 3px 6px 6px' : isLast ? '6px 3px 6px 6px' : '6px')};
  &:active,
  &:focus {
    outline: none;
  }
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    width: auto;
  }
  @media screen and (min-width: ${breakpoints.desktop}) {
    width: 272px;
  }
`;
